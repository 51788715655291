<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">订单号</label>
        <el-input
          v-model="searchOrderNo"
          placeholder="订单号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchSchoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学生姓名</label>
        <el-input
          style="width: 180px"
          v-model="searchStudentName"
          placeholder="学生姓名"
          autocomplete="off"
          size="small"
        >
        </el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          style="width: 160px"
          v-model="searchCreateDate"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
        >
          查询
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table :data="orderList" v-loading="tableLoading" stripe border height="100%">
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="orderNo" label="订单号" width="180"></el-table-column>
        <el-table-column prop="shotTypeCode" label="采集类型" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.shotTypeCode == 1 ? "散拍" : "校拍" }}
          </template>
        </el-table-column>
        <el-table-column prop="schoolName" label="学校名称" width="280">
        </el-table-column>
        <el-table-column prop="taskName" label="采集名称"> </el-table-column>
        <el-table-column prop="studentName" label="学生姓名" width="100" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.studentName, "name") }}</span></template
          >
        </el-table-column>
        <!--                <el-table-column prop="taskDate" label="采集时间"></el-table-column>-->
        <el-table-column
          prop="createtime"
          :formatter="columnDateFormat"
          label="下单时间"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="shotPrice" label="金额" width="70" align="center">
        </el-table-column>
        <el-table-column
          prop="pathCollectedPhoto"
          label="采集状态"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.pathCollectedPhoto === null ? "未采集" : "已完成" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="pathCollectedPhoto"
          label="图片状态"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.pathCollectedPhoto === null ? "未上传" : "已上传" }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态" width="80" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 0"
              type="warning"
              effect="plain"
              size="small"
              >下单</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 1"
              type="success"
              effect="plain"
              size="small"
              >预付款
            </el-tag>
            <el-tag v-else-if="scope.row.status == 2" type="" effect="plain" size="small"
              >完成</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small">取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="asynReason" label="异步日志" width="350">
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              style="margin-right: 10px"
              v-if="viewButton"
              type="text"
              @click="handleView(scope.row.id)"
              >查看
            </el-button>
            <el-popconfirm
              confirm-button-text="确定"
              @confirm="handleImport(scope.row.id)"
              cancel-button-text="取消"
              icon="el-icon-info"
              title="您确定要执行此操作吗"
            >
              <el-button type="text" slot="reference">回传原图</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>


    <!--    上传学生照片-->
    <el-dialog
      title="上传学生照片"
      :visible.sync="dlgImportVisible"
      width="500px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :rules="importRules" :model="fileForm" ref="fileForm" label-width="80px">
        <el-form-item label="文件：" prop="file">
          <el-upload
            class="upload-demo"
            ref="uploadImport"
            :multiple="false"
            action=""
            :limit="1"
            :on-change="handleChange"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg,image/png"
            list-type="picture"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary">选取文件 </el-button>
            <el-button
              style="margin-left: 10px"
              size="small"
              type="success"
              :loading="uploadLoading"
              @click="submitUpload"
              >上传
            </el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg,jpeg,png文件，尺寸为480X640
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="人像识别" prop="mode">
          <el-radio-group v-model="fileForm.isFaceDetect">
            <el-radio :label="0">不启用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日志" prop="importMsg">
          <el-alert
            :visible.sync="msgVisible"
            v-model="importMsg"
            :title="importMsg"
            show-icon
            :type="importSatus"
          >
          </el-alert>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 30px 0 10px 0; text-align: center"
      >
        <el-button @click="dlgImportVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
      <!-- 查看订单详情 -->
      <orderinfo ref="orderInfoFrom" />


  </div>
</template>

<script>
import { getSchoolFiller } from "@/api/school";
import { dateFormat } from "@/utils/date";
import {
  getOrder,
  uploadPhoto,
  reUploadPhoto,
  getRegionsByParentId,
  updateMailinfo,
  getFailedOrders,
  getIgnorePhoto,
} from "@/api/order";
import { queryPageButton } from "@/api/permission";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import orderinfo from "@/components/orderinfo.vue";

export default {
  name: "FailedOrders",
  components: {
    orderinfo,//查看详情
  },
  data() {
    const validateCollectedTimeBegin = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集开始时间不能为空"));
      } else {
        callback();
      }
    };
    const validateCollectedTimeEnd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集结束时间不能为空"));
      } else {
        callback();
      }
    };
    const validateMode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择打包模式"));
      } else {
        callback();
      }
    };
    return {
      searchOrderNo: "",
      searchStudentName: "",
      searchTel: "",
      searchCreateDate: "",
      searchCreateDateEnd: "",
      searchSchoolId: null,
      searchStatus: null,
      searchShotType: "1",
      orderList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,


      //学校
      schools: [],
      value: [],
      allSchools: [],
      loading: false,

      //批量导入
      orderId: "",
      msgVisible: false,
      importSatus: "info",
      importMsg: "",
      dlgImportVisible: false,
      uploadLoading: false,
      isReImport: false,
      fileForm: {
        isFaceDetect: 1,
        name: "",
        file: null,
      },
      hasFile: false,
      fileList: [],
      importRules: {
        file: [{ required: true, message: "请选择上传文件", trigger: "blur" }],
      },

      dlgDownloadVisible: false,
      downloadForm: {},
      downloadRules: {
        collectedTimeBegin: [
          {
            required: true,
            trigger: "blur",
            validator: validateCollectedTimeBegin,
          },
        ],
        collectedTimeEnd: [
          {
            required: true,
            trigger: "blur",
            validator: validateCollectedTimeEnd,
          },
        ],
        mode: [
          {
            required: true,
            trigger: "blur",
            validator: validateMode,
          },
        ],
      },

      //邮寄地址
      dlgMailVisible: false,
      mailForm: {},
      proviceOptions: [],
      cityOptions: [],
      countyOptions: [],

      //按钮权限
      searchButton: false,
      viewButton: false,
      uploadButton: false,
      cencelButton: false,
      downloadSpButton: false,
      reUploadButton: false,
      mailButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchools();
  },
  methods: {
    queryButton() {
      const data = 18;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "upload") {
            this.uploadButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "downloadSp") {
            this.downloadSpButton = true;
          }
          if (button.code === "reUpload") {
            this.reUploadButton = true;
          }
          if (button.code === "mail") {
            this.mailButton = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      this.tableLoading = true;
      const data = {
        orderNo: this.searchOrderNo,
        studentName: this.searchStudentName,
        schoolId: this.searchSchoolId,
        //status: this.searchStatus,
        // shotTypeCode: this.searchShotType,
        //tel: this.searchTel,
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
      };

      getFailedOrders(this.currentPage, this.pageSize, data).then((resp) => {
        this.orderList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    handleUpdateMail() {
      const data = this.mailForm;
      updateMailinfo(data)
        .then((resp) => {
          this.$notify({
            title: "修改邮寄地址",
            message: resp.message,
            type: "success",
          });
          this.dlgMailVisible = false;
        })
        .catch((error) => {});
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchOrderNo = "";
      this.searchStudentName = "";
      this.searchTel = "";
      this.searchCreateDate = "";
      this.searchCreateDateEnd = "";
      this.searchSchoolId = null;
      this.searchStatus = null;
      this.searchShotType = 1;
      this.currentPage = 1;
      this.query();
    },
    //回传原图
    handleImport(id) {
      getIgnorePhoto(id).then((resp) => {
        if (resp.data.code == 0) {
          this.query();
          this.$notify({
            title: "成功",
            message: resp.message,
            type: "success",
          });
        } else {
          this.$notify.warning({
            title: "警告",
            message: "图片回传失败",
          });
        }
      });
    },
    handleReImport(id) {
      if (this.$refs.fileForm !== undefined) {
        //重置表单信息
        this.$refs.fileForm.resetFields();
      }
      this.orderId = id;
      this.isReImport = true;
      this.dlgImportVisible = true;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
      this.uploadLoading = false;
    },
    handleMail(id) {
      this.orderId = id;
      this.dlgMailVisible = true;
      this.getProvice();
      getOrder(id)
        .then((resp) => {
          if (resp.data) {
            this.mailForm = resp.data;
            this.mailForm.id = this.orderId;
          }
        })
        .catch((error) => {});
    },
    handleView(id) {
      if (this.$refs.orderInfoFrom != undefined) {
        this.$refs.orderInfoFrom.initData(id)
      }
    },
    //上传学生照片
    submitUpload() {
      this.uploadLoading = true;
      //this.$refs.upload.submit();
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles(); // 清除上传的文件
      }
      this.fileList = [];

      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          let dataPar = this.fileForm;
          // const file = dataPar.file;
          // const size = file.size / 1024;
          // if (size > 400) {
          //     this.$notify.warning({
          //         title: "警告",
          //         message: "大小必须小于400K",
          //     });
          //     return;
          // }

          let fdParams = new FormData();
          fdParams.append("id", this.orderId);
          fdParams.append("isFaceDetect", dataPar.isFaceDetect);
          fdParams.append("file", dataPar.file.raw);
          if (this.isReImport) {
            reUploadPhoto(fdParams).then((resp) => {
              if (resp.data.code == 0) {
                this.importMsg = resp.data.msg;
                this.importSatus = "success";
                this.dlgImportVisible = false;
                this.$notify({
                  title: "上传照片成功！",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.importMsg = resp.data.msg;
                this.importSatus = "error";
              }
              this.uploadLoading = false;
              this.msgVisible = false;
              this.query();
            });
          } else {
            uploadPhoto(fdParams).then((resp) => {
              if (resp.data.code == 0) {
                this.importMsg = resp.data.msg;
                this.importSatus = "success";
                this.dlgImportVisible = false;
                this.$notify({
                  title: "上传照片成功！",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.importMsg = resp.data.msg;
                this.importSatus = "error";
              }
              this.uploadLoading = false;
              this.msgVisible = false;
              this.query();
            });
          }
        }
      });
    },

    //省
    getProvice() {
      getRegionsByParentId("0")
        .then((resp) => {
          if (resp.data) {
            this.proviceOptions = resp.data;
          }
        })
        .catch((error) => {});
    },
    //市
    getCity(proviceCode) {
      getRegionsByParentId(proviceCode)
        .then((resp) => {
          if (resp.data) {
            this.cityOptions = resp.data;
            this.mailForm.regionCity = resp.data[0].id;
            this.getCounty(this.mailForm.regionCity);
          }
        })
        .catch((error) => {});
    },
    //县
    getCounty(cityCode) {
      getRegionsByParentId(cityCode)
        .then((resp) => {
          if (resp.data) {
            this.countyOptions = resp.data;
            this.mailForm.regionCounty = resp.data[0].id;
          }
        })
        .catch((error) => {});
    },

    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.fileForm.file = null;
    },
    handlePreview(file) {
      //预览
      console.log(file);
    },
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.fileForm.file = file;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateTimeFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    }, // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "name") {
        return formatName(str);
      }
      if (mod == "ph") {
        return formatPh(str);
      }
      if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style>
.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 35px;
  line-height: 35px;
  vertical-align: bottom;
}

.divice {
  width: 1200px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}

.photo {
  width: 120px;
  height: 160px;
  position: relative;
}

.photo::before {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
}
</style>
